import { UserRoles } from "~/types";

export default defineNuxtRouteMiddleware((to, from) => {
  const isAuthenticated = useNuxtApp().$auth.checkAuthentication();

  if (!isAuthenticated && to.path !== "/entrar") {
    return navigateTo("/entrar");
  }
  if (isAuthenticated && to.path === "/entrar") {
    const role = useNuxtApp().$auth.role;
    if (role.value === UserRoles.SUPPLIER) {
      return navigateTo("/contratos");
    } else {
      return navigateTo("/");
    }
  }

  return;
});
